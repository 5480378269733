<template>
  <div id="SimulationsInfos">
    <page-loader v-if="loading"></page-loader>

    <template v-if="!loading">
      <div
        class="simulator-step_header"
        v-if="!isEdit"
        style="margin-bottom: 30px !important;"
      >
        <h3 class="simulator-step_title">{{ $t("add_the_new_simulation") }}</h3>
        <p class="simulator-step_description">
          {{ $t("add_name_date_simulation") }}
        </p>
      </div>

      <div class="giga-fields" style="width: 75%;">
        <div class="col-lg-12">
          <div class="giga-input_field ">
            <label class="input-field_label">{{ $t("simulation_name") }}
            <span class="text-danger" >*</span>
            </label>
            <div class="input-field_area">
              <input
                id="simulation-name"
                type="text"
                v-model="$v.simulation.name.$model"
                :disabled="isVIEWER"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div
            class="giga-input_field "
            v-if="userInfo.clientType === this.USER_TYPE.B2B2B"
          >
            <label class="input-field_label">{{$t("siret_beneficiaire")}}
              <span class="text-danger" >*</span>
            </label>
            <div class="input-field_area">
              <input
                id="simulation-siret"
                type="text"
                v-model="$v.simulation.siret.$model"
                :class="{
                  'field-hasError': !siretIsValid || !siretIsAttachedToCompany
                }"
                @input="checkAttachedToCompany"
                :disabled="isVIEWER"
              />
              <small class="form-text text-danger" v-if="!siretIsValid">
                {{ $t("siret_invalid") }}
              </small>
              <small
                class="form-text text-danger"
                v-if="siretIsValid && !siretIsAttachedToCompany"
              >
                {{ $t("siret_not_attached_to_company") }}
              </small>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12"
          v-if="userInfo.clientType !== this.USER_TYPE.B2B2B"
        >
          <div class="giga-input_field ">
            <label class="input-field_label">{{$t("estimated_end_date") }}
              <span class="text-danger">*</span>
            </label>
            <div class="input-field_area">
              <date-picker
                :disabled="isVIEWER"
                id="estimated_end_date"
                v-model="$v.simulation.deliveryDate.$model"
                format="D MMMM YYYY"
                valueType="YYYY-MM-DD"
                :clearable="false"
                :editable="false"
              >
                <template slot="icon-calendar">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 9H23"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5V1"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 5V1"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 5V1"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 14H7"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 14H13"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 14H19"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 18H7"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 18H13"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 18H19"
                      stroke="#132D48"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </date-picker>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="giga-input_field "
            v-if="userInfo.clientType === this.USER_TYPE.B2B2B"
          >
            <label class="input-field_label">{{ $t("agency") }}
              <span class="text-danger" >*</span>
            </label>

            <div class="input-field_area">
              <select
                id="agence"
                v-model="$v.simulation.agence.$model"
                class="small-input"
                @change="onChangeAgence($v.simulation.agence.$model)"
                :disabled="
                  !this.userInfo.admin &&
                    (this.userInfo.role.roleType ===
                      this.ROLE_TYPE.ADMIN_AGENCE ||
                      this.userInfo.role.roleType === this.ROLE_TYPE.USER)
                "
                :class="{
                  'field-hasError':
                    $v.simulation.agence.$anyDirty &&
                    $v.simulation.agence.$invalid
                }"
              >
                <option v-if="userInfo.admin" :value="-1">AUCUNE AGENCE</option>
                <option
                  v-for="agence of agences"
                  :key="agence.id"
                  :value="agence"
                  >{{ agence.raisonSocial }}
                </option>
              </select>
              <div
                class="validation-field-holder"
                v-if="
                  $v.simulation.agence.$anyDirty &&
                    $v.simulation.agence.$invalid
                "
              >
                <small
                  v-if="!$v.simulation.agence.required"
                  class="form-text text-danger"
                >
                  {{ $t("required_field") }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div :class="computedUserWidth">
          <div class="giga-input_field ">
            <label class="input-field_label">{{ $t("users") }}
              <span class="text-danger" >*</span>
            </label>
            <div class="input-field_area">
              <multiselect
                id="user"
                v-model="$v.simulation.users.$model"
                open-direction="bottom"
                :multiple="true"
                :options="users ? users : []"
                label="email"
                track-by="id"
                :searchable="true"
                :closeOnSelect="false"
                :user-select="true"
                :disableUser="disableUserByEmail"
                :class="{
                  'field-hasError':
                    $v.simulation.agence.$anyDirty &&
                    $v.simulation.users.$invalid
                }"
                :disabled="disableSelectUser"
                @close="updateMaxDisplayed"
                ref="multiselectComponent"
              >
                <template v-slot:selection="{ values, isOpen }">
                  <span
                    v-for="(user, index) in values"
                    :key="user.id"
                    class="selection-container"
                  >
                    <span
                      v-if="index < maxDisplayed && !isOpen"
                      class="selected-user"
                    >
                      {{ user.email }}
                      <button
                        @click.stop="removeUser(index)"
                        class="remove-btn"
                      >
                        ✕
                      </button>
                    </span>
                  </span>
                  <span
                    v-if="values.length > maxDisplayed && !isOpen"
                    class="more-users"
                  >
                    ...
                  </span>
                </template>
                <template slot="caret">
                  <div
                    class="multiselect__select d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <b-icon icon="chevron-down"></b-icon>
                    </div>
                  </div>
                </template>
              </multiselect>
              <div
                class="validation-field-holder"
                v-if="
                  $v.simulation.agence.$anyDirty && $v.simulation.users.$invalid
                "
              >
                <small
                  v-if="!$v.simulation.users.required"
                  class="form-text text-danger"
                >
                  {{ $t("required_field") }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SimulationInfoModal></SimulationInfoModal>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import SimulationInfoModal from "@/views/Simulations/SimulationInfoModal";
import eventBus from "@/shared/event.bus";
import { pick } from "lodash";
import PageLoader from "../../../components/static/pageLoader.vue";
import { agenceService } from "@/views/service/agence.service";
import { userService } from "@/views/service/user.service";
import { simulationService } from "@/views/Simulations/services";
import { constants } from "@/shared/constants";
import { helpers } from "@/shared/helper";

export default {
  name: "SimulationInfos",
  components: { SimulationInfoModal, PageLoader },
  validations: {
    simulation: {
      name: {
        required
      },
      deliveryDate: {
        required: requiredIf(function() {
          return !this.fieldsRequiredB2B2B;
        })
      },
      siret: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        }),
        customValidation: function(value) {
          return this.siretIsValid;
        },
        customValidation2: function(value) {
          return this.siretIsAttachedToCompany;
        }
      },
      agence: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      users: {
        required
      }
    }
  },
  async mounted() {
    if (
      this.simulationType === "COMPLEX" &&
      !this.isEdit &&
      this.IS_B2B_OR_BS
    ) {
      setTimeout(() => {
        this.$bvModal.show("simulation-info-modal");
      }, 300);
    }
    if (this.simulation.id) {
      const invalid = this.$v.$invalid;
      eventBus.$emit("invalid-form", invalid);
    } else {
      if (this.userInfo.clientType === this.USER_TYPE.B2B2B) {
        this.setSimulation({
          name: "",
          deliveryDate: null,
          desiredReturnDate: null,
          submissionTechnicalOfferDate: null,
          contractingProjectDate: null,
          workStartDate: null,
          siret: "",
          agence: null,
          users: null,
          isDraft: true
        });
      } else {
        this.setSimulation({
          name: "",
          deliveryDate: null,
          users: null,
          isDraft: true
        });
      }
    }
    this.fetchAgences();
    this.fetchUsers();
    if (this.userInfo.clientType === this.USER_TYPE.B2B2B) {
      if (
        this.userInfo.role?.roleType === this.ROLE_TYPE.ADMIN_AGENCE &&
        this.simulation.users == null
      ) {
        this.$v.simulation.agence.$model = this.userInfo.agence;
        this.fetchUsersByAgency(this.$v.simulation.agence.$model);
      }
      if (
        this.userInfo.role?.roleType === this.ROLE_TYPE.USER &&
        this.simulation.users == null
      ) {
        this.$v.simulation.agence.$model = this.userInfo.agence;
        const users = await this.fetchUsersByAgency(
          this.$v.simulation.agence.$model
        );
        this.$v.simulation.users.$model = Array.of(
          users.find(user => user.email === this.userInfo.email)
        );
      }

      setTimeout(() => {
        if (
          this.userInfo.admin &&
          this.simulation.users &&
          !this.simulation.agence
        ) {
          this.$v.simulation.agence.$model = -1;
          this.$store
            .dispatch("simulation/fetchUsersByAgency", -1)
            .then(users => {
              this.setUsersAgence(users);
              this.$v.simulation.users.$model = this.simulation.users;
            });
        }
      }, 400);
    }
    window.addEventListener("resize", this.updateMaxDisplayed);
    this.updateMaxDisplayed();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateMaxDisplayed);
  },
  beforeDestroy() {
    eventBus.$emit(
      "save-simulation",
      pick(this.simulation, [
        "id",
        "name",
        "deliveryDate",
        "desiredReturnDate",
        "submissionTechnicalOfferDate",
        "contractingProjectDate",
        "workStartDate",
        "comment",
        "siret",
        "users",
        "type",
        "status",
        "isDraft"
      ])
    );
    this.setUsersAgence(null);
  },
  computed: {
    ...mapGetters("simulation", {
      simulation: "getSimulation",
      isEdit: "isEdit",
      simulationType: "getSimulationType",
      users: "getUsers"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("account", { userInfo: "getCurrentUser" }),
    fieldsRequiredB2B2B() {
      return this.userInfo.clientType === this.USER_TYPE.B2B2B;
    },
    siretIsValid() {
      if (this.userInfo.clientType === this.USER_TYPE.B2B2B)
        return this.checkLuhn(this.$v.simulation.siret.$model);
      else return true;
    },
    computedUserWidth() {
      return this.userInfo.clientType === this.USER_TYPE.B2B2B
        ? "col-lg-6"
        : "col-lg-12";
    },
    disableSelectUser() {
      return this.isVIEWER && this.isNotB2B2B;
    },
    isNotB2B2B() {
      return helpers.isNotB2B2B();
    },
    isVIEWER() {
      return helpers.isVIEWER();
    },
    disableUserByEmail() {
      return this.userInfo.role?.roleType === this.ROLE_TYPE.USER
        ? this.userInfo.email
        : null;
    }
  },
  data() {
    return {
      agences: [],
      siretIsAttachedToCompany: true,
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE,
      IS_B2B_OR_BS: helpers.isUserOfType([
        constants.USER_TYPE.B2B,
        constants.USER_TYPE.BAILLEUR_SOCIAL
      ]),
      maxDisplayed: 2
    };
  },
  methods: {
    ...mapMutations("simulation", [
      "setSimulation",
      "setUsersAgence",
      "setSimulationChanged"
    ]),
    ...mapActions("simulation", ["fetchUsersByAgency"]),
    checkLuhn(siret) {
      let nDigits = siret?.length;
      let nSum = 0;
      let isSecond = false;

      for (let i = nDigits - 1; i >= 0; i--) {
        let d = parseInt(siret?.charAt(i), 10);

        if (isSecond) {
          d = d * 2;
        }

        nSum += Math.floor(d / 10);
        nSum += d % 10;

        isSecond = !isSecond;
        if (siret?.length !== 14) {
          return false;
        }
      }
      return nSum % 10 === 0;
    },
    checkAttachedToCompany() {
      if (!this.siretIsValid) {
        return;
      }
      if (
        this.userInfo.clientType === this.USER_TYPE.B2B2B &&
        this.siretIsValid &&
        this.$v.simulation.siret.$model !== ""
      ) {
        simulationService
          .siretAttachedToCompany(this.$v.simulation.siret.$model)
          .then(isValid => {
            this.siretIsAttachedToCompany = isValid;
          });
      }
    },
    fetchAgences() {
      agenceService.allAgencesList().then(res => {
        this.agences = res;
      });
    },
    async onChangeAgence(agency) {
      this.$v.simulation.users.$model = null;
      if (agency === -1) {
        await this.fetchUsersByAgency(agency);
      } else {
        this.fetchUsersByAgency(agency);
      }
    },
    fetchUsers() {
      if (this.isNotB2B2B) {
        userService.clientUsersByRole().then(users => {
          this.setUsersAgence(users);
          if (!this.simulation.id && !this.userInfo.admin && this.isNotB2B2B) {
            this.$v.simulation.users.$model = Array.of(
              this.users.find(user => user.email === this.userInfo.email)
            );
          }
        });
      }
    },
    removeUser(index) {
      this.$v.simulation.users.$model.splice(index, 1);
    },
    updateMaxDisplayed() {
      this.$nextTick(() => {
        const multiselectWidth =
          this.$refs.multiselectComponent?.$el?.offsetWidth || 0;
        this.maxDisplayed = Math.max(1, Math.floor(multiselectWidth / 250));
      });
    }
  },
  watch: {
    "$v.$invalid": function() {
      const invalid = this.$v.$invalid;
      eventBus.$emit("invalid-form", invalid);
    },
    simulation: {
      handler() {
        this.setSimulationChanged(true);
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
.field-hasError {
  box-shadow: 0px 0px 3px 0px #e90e00 !important;
}

.giga-fields .giga-input_field:last-child {
  margin-bottom: 20px;
}

.more-users {
  color: #888;
  font-size: 37px;
  margin-bottom: 2px;
}

.selected-user {
  display: inline-flex;
  align-items: center;
  background: #f0fbf1;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
}

.remove-btn {
  background: none;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

#user {
  display: flex;
  flex-direction: column;
  order: -1;
  width: 100px;
  height: 55%;
  padding: 8px;
  border: 0px;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
